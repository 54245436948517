// Angular
import { AfterViewInit, Component, OnInit } from '@angular/core';

// Layout
import { LayoutConfigService, ToggleOptions } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';

@Component({
  selector: 'kt-brand',
  templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit, AfterViewInit {
  // Public properties
  headerLogo = '';
  brandClasses = '';
  asideSelfMinimizeToggle = true;

  toggleOptions: ToggleOptions = {
    target: 'kt_body',
    targetState: 'aside-minimize',
    toggleState: 'active',
  };

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param htmlClassService: HtmlClassService
   */
  constructor(private layoutConfigService: LayoutConfigService, public htmlClassService: HtmlClassService) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.headerLogo = this.getAsideLogo();
    this.brandClasses = this.htmlClassService.getClasses('brand', true).toString();
    this.asideSelfMinimizeToggle = this.layoutConfigService.getConfig('aside.self.minimize.toggle');
  }

  /**
   * On after view init
   */
  ngAfterViewInit(): void {}

  getAsideLogo() {
    // let result = 'brand-log-bank-original.svg';
    // const brandSelfTheme = this.layoutConfigService.getConfig('brand.self.theme') || '';
    // if (brandSelfTheme === 'light') {
    //   result = 'brand-log-bank-original.svg';
    // }
    // return `./assets/brand/${result}`;
    return this.layoutConfigService.getConfig('wlTheme.aside.brand.logo');
  }

  toggleAsideClick() {
    document.body.classList.toggle('aside-minimize');
  }
}
