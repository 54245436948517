import {LayoutUtilsService} from './../../../../../core/_base/crud/utils/layout-utils.service';
// Angular
import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
// RxJS
import {Subscription} from 'rxjs';
// NGRX
// State

import {Application} from "../../../../../core/_base/layout/models/layout-config.model";
import {Router} from "@angular/router";
import {UserInfoService} from "@wlp/ui-bs-oauth-bo";

@Component({
	selector: 'kt-user-application',
	templateUrl: './user-application.component.html',
	styleUrls: ['./user-application.component.scss']
})
export class UserApplicationComponent implements OnInit, OnDestroy {
	private subscriptions: Subscription[] = [];
	ngbDropdownPlacement: string = 'bottom-right';
  @Input() userDropdownStyle = 'light';
	menuMobile: any;
	applications: Array<Application>;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		private layoutUtilsService: LayoutUtilsService,
		private cdr: ChangeDetectorRef,
    protected readonly router: Router,
    private userInfoService: UserInfoService
	) {

	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
	  // this.applications = this.userInfoService.getApplicationAccess();

	}

  redirectApp(app: Application) {
	  if (app.blank) {
	    window.open(app.url, '_blank');
    } else {
      this.router.navigateByUrl(app.url);
    }
  }

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}


}
