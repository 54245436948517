import { MatSort } from '@angular/material/sort';
import { AccountReviewService } from '@wlp/ui-bs-account-manager';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';


const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
      ? one.month === two.month
        ? one.day === two.day
          ? false
          : one.day < two.day
        : one.month < two.month
      : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
      ? one.month === two.month
        ? one.day === two.day
          ? false
          : one.day > two.day
        : one.month > two.month
      : one.year > two.year;

@Component({
  templateUrl: './risk-management.component.html',
  styleUrls: ['./risk-management.component.scss']
})
export class RiskManagementComponent implements OnInit {

  searchFor = '';
  typeSearch = '';
  fieldSearch = '';
  advancedFilter = false;
  riskType = '';

  accountList: any;

  // Table fields
	dataSource: any;
  displayedColumns = ['code', 'partner', 'opening', 'holder', 'product', 'description', 'sender', 'transactionDate', 'value', 'recipient', 'risk',  'action'];
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('sort1') sort: MatSort;
	// Filter fields
	@ViewChild('searchInput') searchInput: ElementRef;

  selection = new SelectionModel<any>(true, []);
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  datePicker: boolean;

  constructor(
    private calendar: NgbCalendar,
    private accountReviewService: AccountReviewService,
    ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit() {
    this.datePicker = false;
    this.loadList();
  }

  loadList() {
    this.accountList = this.accountReviewService.getAllAccountsReview();
    this.dataSource = new MatTableDataSource(this.accountList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
	}

  chooseSearch(type: string, option: string) {
    this.typeSearch = type;
    this.searchFor = option;
  }

  moreFilters() {
    this.advancedFilter ? this.advancedFilter = false : this.advancedFilter = true;
  }

  search() {
    // console.log('search', this.typeSearch, this.fieldSearch);
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  isEnable() {
    this.datePicker = this.datePicker ? false : true;
  }

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered = (date) =>
    this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = (date) => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = (date) => equals(date, this.fromDate);
  isTo = (date) => equals(date, this.toDate);
}
