<!-- begin:: Footer -->
<div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer" [ngClass]="footerClasses" style="height: auto">
  <div
    [ngClass]="footerContainerClasses"
    class="d-flex flex-column flex-md-row align-items-center justify-content-between"
  >
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1 w-100">
      <span class="text-muted font-family-semibold mr-2">{{ today | date: 'yyyy' }} &copy;</span>
      <span class="text-dark-75 font-family-semibold mr-2">{{ wlName }}</span>
      <span class="text-version font-family-semibold float-right">
        ...::: Backoffice Versão
        <span class="version-color">
          <span class="font-family-bold"> {{ versionProject }}</span>
        </span>
        :::...
      </span>
    </div>
    <!--end::Copyright-->

    <!--begin::Nav-->
    <!-- <div class="nav nav-dark order-1 order-md-2">
			<a href="" target="_blank" class="nav-link pr-3 pl-0">About</a>
			<a href="" target="_blank" class="nav-link px-3">Team</a>
			<a href="" target="_blank" class="nav-link pl-3 pr-0">Contact</a>
		</div> -->
  </div>
</div>
<!-- end:: Footer -->
