<!--begin::User-->
<div ngbDropdown placement="bottom-right" *ngIf="applications" class="dropdown dropdown-fluid kt-margin-l-10">
  <!--begin::Toggle-->
  <div ngbDropdownToggle class="topbar-item">
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">

      <span class="symbol symbol-35 symbol-light-success">
				<!-- <span class="symbol-label font-size-h5 font-weight-bold">{{userProfile.nameFirst|firstLetter}}</span> -->
        <img  alt="Application" [attr.src]="'./assets/icon/menu-application.svg'">
			</span>
    </div>
  </div>
  <!--end::Toggle-->

  <!--begin::Dropdown-->
  <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
    <ng-container *ngIf="userDropdownStyle === 'light'">
      <!--begin: Head -->
      <div class="d-flex align-items-center p-8 rounded-top">
        <!--begin::Text-->
        <div class="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
          Aplicativos
        </div>
        <span class="label label-light-success label-lg font-weight-bold label-inline" *ngIf="false">3 messages</span>
        <!--end::Text-->
      </div>
      <!--end: Head -->
    </ng-container>


    <!--begin::Nav-->
    <div class="navi navi-spacer-x-0 pt-5">
      <!--begin::Item-->

      <div class="row kt-padding-l-20" >
        <ng-container *ngFor="let app of applications">
          <a ngbDropdownToggle (click)="redirectApp(app)" *ngIf="app.isVisible" class="kt-notification__item kt-margin-l-15 kt-margin-t-5 col-lg-5">
            <div class="kt-notification__item-details kt-align-center">
              <div>
                <img height="40" [alt]="app.label" [attr.src]="app.img">
              </div>
              <div>
                {{ app.label }}
              </div>
            </div>
          </a>
        </ng-container>
      </div>
      <!--end::Item-->
      <!--end::Footer-->
    </div>
    <!--end::Nav-->
  </div>
  <!--end::Dropdown-->
</div>
<!--end::User-->
