<div class="risk-management-page">
  <ui-c-subheader [menuTitle]="'Gestão de Risco e Complaince'">
  </ui-c-subheader>
  <div class="card-status row m-0">
    <div class="col bg-light-success px-6 py-2 m-3 rounded-xl">
      <i class="icon-success icon-4x fas fa-check"></i>
      <p class="text-success font-family-bold font-size-h5 mb-0 mt-2"> 9.763.776 </p>
      <p class="text-success font-family-bold font-size-h6 mb-0"> Extrato de transações </p>
    </div>
    <div class="col bg-light-danger px-6 py-2 m-3 rounded-xl">
      <i class="icon-danger icon-4x fas fa-times"></i>
      <p class="text-danger font-family-bold font-size-h5 mb-0 mt-2"> 97.646 </p>
      <p class="text-danger font-family-bold font-size-h6 mb-0"> Limite diário exedido </p>
    </div>
    <div class="col bg-light-primary px-6 py-2 m-3 rounded-xl">
      <i class="icon-primary icon-4x fas fa-exchange-alt"></i>
      <p class="text-primary font-family-bold font-size-h5 mb-0 mt-2"> 797 </p>
      <p class="text-primary font-family-bold font-size-h6 mb-0"> Limite por transação exedido </p>
    </div>
    <div class="col bg-light-danger px-6 py-2 m-3 rounded-xl">
      <i class="icon-danger icon-4x fas fa-times"></i>
      <p class="text-danger font-family-bold font-size-h5 mb-0 mt-2"> 976 </p>
      <p class="text-danger font-family-bold font-size-h6 mb-0"> Transações iguais por dia </p>
    </div>
    <div class="col bg-light-danger px-6 py-2 m-3 rounded-xl">
      <i class="icon-danger icon-4x fas fa-times"></i>
      <p class="text-danger font-family-bold font-size-h5 mb-0 mt-2"> 97.645 </p>
      <p class="text-danger font-family-bold font-size-h6 mb-0"> Contas em Complience </p>
    </div>
  </div>
  <div class="card-status row m-0">
    <div class="col bg-light-success px-6 py-2 m-3 rounded-xl">
      <i class="icon-success icon-4x fas fa-check"></i>
      <p class="text-success font-family-bold font-size-h5 mb-0 mt-2"> 976.377 </p>
      <p class="text-success font-family-bold font-size-h6 mb-0"> Transaçõoes de valores aproximados </p>
    </div>
    <div class="col bg-light-danger px-6 py-2 m-3 rounded-xl">
      <i class="icon-danger icon-4x fas fa-times"></i>
      <p class="text-danger font-family-bold font-size-h5 mb-0 mt-2"> 976 </p>
      <p class="text-danger font-family-bold font-size-h6 mb-0"> Inquérito Policial (Fraude) </p>
    </div>
    <div class="col bg-light-primary px-6 py-2 m-3 rounded-xl">
      <i class="icon-primary icon-4x fas fa-exchange-alt"></i>
      <p class="text-primary font-family-bold font-size-h5 mb-0 mt-2"> 797 </p>
      <p class="text-primary font-family-bold font-size-h6 mb-0"> Repetição de Comportamento </p>
    </div>
    <div class="col bg-light-danger px-6 py-2 m-3 rounded-xl">
      <i class="icon-danger icon-4x fas fa-times"></i>
      <p class="text-danger font-family-bold font-size-h5 mb-0 mt-2"> 976.457 </p>
      <p class="text-danger font-family-bold font-size-h6 mb-0"> Boletos recebidos em Dinheiro </p>
    </div>
  </div>

  <form class="form">
    <div class="card-search card card-custom">
      <div class="card-body">
        <div class="form-group p-0">
          <div class="d-flex justify-content-between p-0">
            <div class="input-group">
              <div class="input-group-append">
                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle>Pesquisar Por
                    {{searchFor}}</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button class="dropdown-item" (click)="chooseSearch('account', 'Conta')">Conta</button>
                    <button class="dropdown-item" (click)="chooseSearch('document', 'CPF/CNPJ')">CPF/CNPJ</button>
                    <button class="dropdown-item" (click)="chooseSearch('phone', 'Celular')">Celular</button>
                    <button class="dropdown-item" (click)="chooseSearch('email', 'Email')">Email</button>
                    <button class="dropdown-item" (click)="chooseSearch('name', 'Cliente')">Cliente</button>
                    <button class="dropdown-item" (click)="chooseSearch('card', 'Cartão')">Número do Cartão</button>
                    <button class="dropdown-item" (click)="chooseSearch('pos', 'POS')">Número do POS</button>
                  </div>
                </div>
              </div>
              <input type="text" class="form-control" placeholder="" />
            </div>
            <div class="row">
              <div class="mx-3">
                <button class="btn btn-secondary" type="button" (click)="moreFilters()">{{advancedFilter ? 'Menos' : 'Mais'}} Filtros</button>
              </div>
              <div class="mx-3">
                <button class="btn btn-primary" type="button" (click)="search()">Pesquisar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-0 mt-5" *ngIf="advancedFilter">
      <h4>Filtros Avançados</h4>
      <div class="card-advanced-filter card card-custom">
        <div class="card-body">
          <div class="form-group p-0">
            <div class="d-flex justify-content-between p-0">
              <div class="input-group mr-3">
                <input type="text" class="form-control" placeholder="Produto" />
              </div>
              <div class="input-group ml-3">
                <div ngbDropdown>
                  <button
                    class="btn btn-secondary btn-size btn-date-picker d-flex justify-content-between align-items-center"
                    ngbDropdownToggle (click)="isEnable()" aria-haspopup="true">
                    Período
                    <div class="date-picker" *ngIf="datePicker">
                      <ngb-datepicker #dp ngModel (ngModelChange)="onDateChange($event)" [displayMonths]="2"
                        [dayTemplate]="t">
                      </ngb-datepicker>
                      <ng-template #t let-date="date" let-focused="focused">
                        <span class="custom-day" [class.focused]="focused"
                          [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                          [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                          (mouseleave)="hoveredDate = null">
                          {{ date.day }}
                        </span>
                      </ng-template>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="card card-custom table-col mt-10">
    <div class="row">
      <div class="col text-right mb-3">
        <div ngbDropdown placement="top-right" class="d-inline-block">
          <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle>Exportar</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="dropdown-item">CSV</button>
            <button class="dropdown-item">PDF</button>
          </div>
        </div>
      </div>
    </div>

    <table mat-table #table [dataSource]="dataSource" matSort #sort1="matSort" matSortActive="id" matSortDirection="asc"
      matSortDisableClear>
      <ng-container matColumnDef="code">
        <th *matHeaderCellDef class="pl-5">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
          ID
        </th>
        <td *matCellDef="let account" class="pl-5">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(account) : null"
            [checked]="selection.isSelected(account)">
          </mat-checkbox>
          {{ account.uuid }}
        </td>
      </ng-container>
      <ng-container matColumnDef="partner">
        <th *matHeaderCellDef class="font-family-bold">Parceiro</th>
        <td *matCellDef="let account" class="font-family-semibold">{{ account.openingDate }}</td>
      </ng-container>
      <ng-container matColumnDef="opening">
        <th *matHeaderCellDef class="font-family-bold">Abertura</th>
        <td *matCellDef="let account" class="font-family-semibold">{{ account.document }}  {{ account.holder }}</td>
      </ng-container>
      <ng-container matColumnDef="holder">
        <th *matHeaderCellDef class="font-family-bold">Correntista</th>
        <td *matCellDef="let account" class="font-family-semibold">{{ account.type }}</td>
      </ng-container>
      <ng-container matColumnDef="product">
        <th *matHeaderCellDef class="font-family-bold">Produto</th>
        <td *matCellDef="let account" class="font-family-semibold">{{ account.limit }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th *matHeaderCellDef class="font-family-bold">Descrição</th>
        <td *matCellDef="let account" class="font-family-semibold">{{ account.document }}</td>
      </ng-container>
      <ng-container matColumnDef="sender">
        <th *matHeaderCellDef class="font-family-bold">Remetente</th>
        <td *matCellDef="let account" class="font-family-bold">
          <span class="label label-lg label-light-warning label-inline">{{ account.risk }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionDate">
        <th *matHeaderCellDef class="font-family-bold">Data Transação</th>
        <td *matCellDef="let account" class="font-family-semibold">{{ account.document }}</td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th *matHeaderCellDef class="font-family-bold">Valor</th>
        <td *matCellDef="let account" class="font-family-semibold">{{ account.document }}</td>
      </ng-container>
      <ng-container matColumnDef="recipient">
        <th *matHeaderCellDef class="font-family-bold">Destinatário</th>
        <td *matCellDef="let account" class="font-family-semibold">{{ account.document }}</td>
      </ng-container>
      <ng-container matColumnDef="risk">
        <th *matHeaderCellDef class="font-family-bold">Risco</th>
        <td *matCellDef="let account" class="font-family-semibold">{{ account.document }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="font-family-bold">Ações</th>
        <td *matCellDef="let account">
          <a class="btn btn-icon btn-light btn-sm mx-3" href="#">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Stockholm-icons-/-Communication-/-Write" stroke="none" stroke-width="1" fill="none"
                  fill-rule="evenodd">
                  <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                    id="Path-11" fill="#000000" fill-rule="nonzero"
                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) ">
                  </path>
                  <path
                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                    id="Path-57" fill="#000000" fill-rule="nonzero" opacity="0.3">
                  </path>
                </g>
              </svg>
            </span>
          </a>
        </td>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>

</div>
