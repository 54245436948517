import { environment } from 'src/environments/environment';

import { LayoutConfigModel } from '../_base/layout';

export class LayoutConfig {
  public defaults = environment.wlThemeParameterization;

  /**
   * Good place for getting the remote config
   */
  public get configs(): LayoutConfigModel {
    return this.defaults;
  }
}
